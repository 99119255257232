import { render, staticRenderFns } from "./NutrientMatrix.vue?vue&type=template&id=3f0a520f"
import script from "./NutrientMatrix.vue?vue&type=script&lang=js"
export * from "./NutrientMatrix.vue?vue&type=script&lang=js"
import style0 from "./NutrientMatrix.vue?vue&type=style&index=0&id=3f0a520f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports